<template>
  <div class="product-payments">
    <button class="product-payments__toggle" @click="toggle">
      Payment Options {{ isOpen ? '–' : '+' }}
    </button>
    <TransitionHeightWrap :active="isOpen">
      <ProductPayment
        :product="product"
        :count="4"
        class="product-payments__payment"
      >
        <a href="https://zip.co/nz/how-it-works/" target="_blank" rel="none">
          Zip
        </a>
      </ProductPayment>

      <ProductPayment
        :product="product"
        :count="4"
        class="product-payments__payment"
      >
        <a
          href="https://www.afterpay.com/en-NZ/how-it-works"
          target="_blank"
          rel="none"
        >
         Afterpay
        </a>
      </ProductPayment>
    </TransitionHeightWrap>
  </div>
</template>

<script>
import ProductPayment from './ProductPayment.vue'
import TransitionHeightWrap from '../transition/TransitionHeightWrap.vue'

export default {
  components: { ProductPayment, TransitionHeightWrap },
  props: {
    product: Object,
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base.scss';

.product-payments {
  margin-top: 24px;
  &__toggle {
    margin-bottom: 12px;
    padding-bottom: 2px;
    @include helper-underline;
  }
  &__payment {
    padding-top: 4px;
  }
}
</style>