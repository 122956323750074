<template>
  <form
    class="product-purchase"
    :class="isAdding ? 'product-purchase--disabled' : ''"
    @submit.prevent="onSubmit"
  >
    <ProductOptions class="product-purchase__select" :product="product" />
    <button class="product-purchase__button" :disabled="isUnavailable">
      <span class="product-purchase__text">{{ message }}</span>
      <span v-if="isUnavailable" class="product-purchase__message"
        >Sold Out</span
      >
      <span v-else-if="isLowInStock" class="product-purchase__message"
        >Last One</span
      >
    </button>
  </form>
</template>

<script>
import ProductOptions from './ProductOptions.vue'
import ProductMixin from '~/mixins/product'

export default {
  components: { ProductOptions },
  mixins: [ProductMixin],
  props: {
    product: Object,
  },
  data() {
    return {
      isAdding: false,
    }
  },
  computed: {
    settings() {
      return this.$store.state.shopify.settings
    },
    isPreorder() {
      return this.product.tags.find((v) => v == 'preorder')
    },
    message() {
      if (this.isAdding) return 'Adding to cart'
      if (this.isPreorder) return 'Pre-order'
      return 'Add to Cart'
    },
  },
  methods: {
    async onSubmit() {
      if (this.isUnavailable) return
      this.isAdding = true
      try {
        await this.$store.dispatch('cart/add', {
          id: this.variant.id,
        })
        this.$store.commit('overlay/set', 'cart')
      } catch (e) {
        console.log(e)
      }
      this.isAdding = false
    },
  },
}
</script>

<style lang="scss">
@import '~/styles/base';

.product-purchase {
  margin: 0 calc(-1 * var(--gutter)) 0 calc(-1 * var(--inset));
  display: flex;
  transition: all 0.2s ease;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  width: 100%;

  @include respond-to('m') {
    flex-direction: column;
    width: 96.5%;
  }

  @include respond-to('m+') {
    border-top: 1px solid var(--foreground);
    > * + * {
      border-left: 1px solid var(--foreground);
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &__select,
  &__button {
    display: inline-flex;
    padding-top: 10px;
    padding-bottom: 10px;
    background: var(--background);
    padding: 10px var(--gutter) 10px var(--inset);
    flex: 1;

    &:hover {
      --foreground: var(--color-light);
      background: var(--color-dark);
    }
  }

  &__select {
    margin-right: auto;

    @include respond-to('m') {
      border-top: 1px solid var(--foreground);
      /* width: 100%; */
      margin-right: -16px;
    }
  }

  &__button {
    @include cx-uc;

    display: flex;
    justify-content: space-between;
    align-items: middle;

    &:disabled {
      color: var(--color-lightgrey);
      pointer-events: none;
    }

    @include respond-to('m+') {
      min-width: 240px;
      width: 50%;
      padding-left: var(--gutter);
      padding-right: var(--gutter);
    }

    @include respond-to('m') {
      border-top: 1px solid var(--foreground);
      /* margin-right: calc(-1 * (var(--inset) + var(--scroll-inset))); */
      margin-right: -15px;
      padding-right: 5px;
    }
  }

  &__text {
    @include cx-l;
  }

  &__message {
    margin: auto 0;
  }
}
</style>